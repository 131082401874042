<template>
  <div class="uploader__nav">
    <button @click="decreaseZoom" :disabled="imageZoom <= 1" class="uploader__nav-btn">
      <img src="@/assets/img/icons/uploader--zoomOut.svg" alt="-" />
    </button>
    <div class="uploader__nav-range">
      <input
        type="range"
        v-model.number="computedImageZoom"
        min="1"
        max="4"
        step="1"
        :style="`--min: 1;--max: 4;--val: ${computedImageZoom};`"
        ref="inputRange"
      />
    </div>
    <button @click="increaseZoom" :disabled="imageZoom >= 4" class="uploader__nav-btn">
      <img src="@/assets/img/icons/uploader--zoomIn.svg" alt="+" />
    </button>
    <button class="uploader__nav-btn" @click="showLightBox">
      <img src="@/assets/img/icons/uploader--fullscreen.svg" alt="[ ]" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    imageZoom: Number
  },
  methods: {
    styleInputRange() {
      document.documentElement.classList.add('js')
      this.$refs.inputRange.addEventListener('input', this.addStyleToRange, false)
    },
    addStyleToRange() {
      this.$refs.inputRange.style.setProperty('--val', this.$refs.inputRange.value)
    },
    increaseZoom() {
      this.$emit('increaseZoom')
    },
    decreaseZoom() {
      this.$emit('decreaseZoom')
    },
    showLightBox() {
      this.$emit('showLightBox')
    }
  },
  mounted() {
    this.styleInputRange()
  },
  computed: {
    computedImageZoom: {
      get() {
        return this.imageZoom
      },
      set(value) {
        this.$emit('updateZoom', value)
      }
    }
  }
}
</script>
<style scoped lang="sass">
@import '~@/assets/sass/_input-range.scss'
.uploader
  display: flex
  overflow: hidden
  flex-direction: column
  justify-content: center
  align-items: center
  background: $default
  box-shadow: inset 0 rem(-1) 0 #ECECEC
  // min-height: rem(176)
  &__loaded-img
    overflow: hidden
    line-height: 0
    &.grabbing
      cursor: grab
    &--picture
      line-height: 0
      max-width: 100%
      transform: scale(1)
      transform-origin: 0 0
  &__pic
    padding: rem(28) 0 rem(19)
  &__errors
    display: flex
    flex-direction: column
    padding: 0 rem(20) 0 rem(40)
    margin: 0
    &-item
      @extend .fs-12
      color: $red
      &:last-child
        padding-bottom: rem(10)
  &__btn
    @extend .fs-12
    margin-bottom: rem(16)
    cursor: pointer
    display: flex
    flex-direction: row
    align-items: center
    padding: rem(9) rem(14)
    border: rem(1) dashed $dark-grey
    box-sizing: border-box
    border-radius: rem(4)
    min-width: rem(188)
    transition: .5s
    &:hover
      border-color: $green
    .icon
      margin-right: rem(17)
    &-input
      display: none
      pointer-events: none
      opacity: 0
      position: absolute
      font-size: 0
  &__nav
    width: 100%
    flex-direction: row
    justify-content: center
    align-items: center
    background: #ECECEC
    display: grid
    z-index: 1
    grid-template-columns: rem(40) 5fr rem(40) rem(40)
    grid-column-gap: rem(1)
    box-shadow: 0 rem(1) rem(5) rgba(51, 51, 51, 0.5)
    &-range
      background: #ffffff
      width: 100%
      height: rem(40)
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
    &-btn
      cursor: pointer
      height: rem(40)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      border: none
      box-shadow: none
      outline: none
      transition: .5s
      background: #fff
      &:disabled
        opacity: .5
  &__delete
    position: absolute
    z-index: 1
    @extend .clear-btn
    background: #fff
    height: rem(40)
    width: rem(40)
    top: 0
    right: 0
    svg
      path
        transition: .5s
    &:hover
      background: $red-hover
      svg
        path
          stroke: #fff
</style>
