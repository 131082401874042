<template>
  <modal
    :name="modalName"
    draggable=".modal-body__nav-drag"
    :resizable="true"
    :clickToClose="false"
    :minWidth="125"
    :minHeight="125"
    :width="initialWidth"
    :height="initialHeight"
    :shiftX="0.98"
  >
    <div class="modal-body">
      <div
        class="modal-body__overlay dragscroll"
        :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
        ref="imageWrapper"
      >
        <div
          class="modal-body__img"
          :style="{ backgroundImage: `url(${this.url})`, transform: `scale(${imageZoom})` }"
          :class="{ grabbing: imageZoom > 1 }"
        ></div>
      </div>
      <div class="modal-body__nav">
        <button class="btn" @click="close">
          <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" />
        </button>
        <div class="modal-body__nav-drag"></div>
        <div class="modal-body__nav-zoom">
          <button class="btn" @click="updateDrag" :disabled="imageZoom >= 4">
            <img src="@/assets/img/icons/uploader--modal-zoomIn.svg" alt="+" />
          </button>
          <button class="btn" @click="imageZoom -= 1" :disabled="imageZoom <= 1">
            <img src="@/assets/img/icons/uploader--modal-zoomOut.svg" alt="-" />
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import dragscroll from 'dragscroll/dragscroll.js'
import device from 'current-device'

export default {
  directives: {
    dragscroll: dragscroll
  },
  props: {
    isShow: Boolean,
    url: String,
    xPosition: {
      type: Number,
      default: 0.5
    }
  },
  data: () => ({
    imageZoom: 1,
    isDesktop: '',
    modalName: 'lightbox-modal',
    initialWidth: 800,
    initialHeight: 663
  }),
  mounted() {
    this.isDesktop = device.desktop()
    if (this.isDesktop) {
      this.initialWidth = 320
      this.initialHeight = 320
    }
    if (this.isFrame) {
      this.$modal.context.root.$el.classList.add('is-frame')
    }
  },
  computed: {
    isFrame() {
      return window.self !== window.top
    }
  },
  methods: {
    updateDrag() {
      dragscroll.reset()
      this.imageZoom += 1
    },
    close() {
      this.$emit('close')
    }
  },
  watch: {
    imageZoom() {
      if (this.imageZoom > 1) {
        const wrapper = this.$refs.imageWrapper
        const realHeight = wrapper.offsetHeight
        const realWidth = wrapper.offsetWidth
        setTimeout(() => {
          wrapper.scrollTo(
            (realWidth * this.imageZoom) / 2 - realWidth / 2,
            (realHeight * this.imageZoom) / 2 - realHeight / 2
          )
        }, 0)
      }
    },
    isShow() {
      if (this.isShow) {
        this.imageZoom = 1
        dragscroll.reset()
        this.$modal.show(this.modalName)
        this.$modal.context.root.$el.style.setProperty('--top-position', `${this.xPosition}px`)
      } else {
        this.$modal.hide(this.modalName)
      }
    }
  }
}
</script>

<style lang="sass">
.vm--container
  height: 0
  z-index: 1
.vm--overlay
  display: none
.vm--modal
  box-shadow: $default-box-shadow
  border-radius: rem(4)
.vm-transition--modal-enter, .vm-transition--modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)

#app.is-frame
  .vm--modal
    top: var(--top-position)!important
</style>

<style scoped lang="sass">
.modal-body
  width: 100%
  height: 100%
  box-sizing: border-box
  overflow: hidden
  display: flex
  +media((flex-direction: (320: column-reverse, 663: row)))
  align-items: center
  background: #FFFFFF
  +media((padding: (320: 0 rem(12) rem(12), 663: rem(12) 0 rem(12) rem(12))))
  &__overlay
    +media((width: (320: 100%, 663: calc(100% - 64px))))
    +media((height: (320: rem(300), 663: 100%)))
  &__img
    +media((height: (320: rem(300), 663: 100%)))
    width: 100%
    background-position: center
    background-repeat: no-repeat
    background-color: $default
    transform-origin: 0 0
    background-size: contain
    &.grabbing
      cursor: grab
  &__nav
    display: flex
    +media((width: (320: 100%, 663: rem(64))))
    +media((height: (320: rem(64), 663: 100%)))
    justify-content: space-between
    align-items: center
    +media((flex-direction: (320: row-reverse, 663: column)))
    &-drag
      background-image: url('~@/assets/img/icons/drag.png')
      background-repeat: no-repeat
      background-position: center
      flex: 1
      width: 100%
      cursor: move
    &-zoom
      display: flex
      +media((flex-direction: (320: row-reverse, 663: column)))
      .btn
        &:last-child
          box-shadow: none
    .btn
      background: none
      +media((box-shadow: (320: none, 663: inset 0 rem(-1) 0 #ECECEC)))
      border: none
      outline: none
      cursor: pointer
      height: rem(48)
      width: rem(32)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      transition: .5s
      &:disabled
        opacity: .5
</style>
